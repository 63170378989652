/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { shape as shapeType, bool, string } from 'prop-types';
import { Typography, Button, Link } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { getMediaUrl } from './utils/getMediaUrl';
import { getTarget } from './utils/getTarget';

const MedioComposableBannerRenderer = ({
  data, componentId, componentClass
}) => {
  const {
    image,
    altText,
    smallMedia,
    altTextSmallMedia,
    title,
    subtitle,
    cta,
    destinationUrl,
    legalCopy
  } = data;
  const imageUrl = getMediaUrl(image);
  const smallMediaUrl = getMediaUrl(smallMedia);
  const { legalText = '', legalUrl = '' } = legalCopy || {};

  const mainContent = (
    <div
      className={classNames(
        'sui-flex',
        'sui-flex-col',
        'sm:sui-flex-row',
        'sm:sui-h-[148px]',
        'md:sui-h-[116px]',
        'lg:sui-h-[118px]',
        'xl:sui-h-[134px]',
        '2xl:sui-h-[119px]',
        {
          'hover:sui-shadow-lg': !cta && destinationUrl,
        }
      )}
    >
      {/* Image */}
      <div
        data-contentful-field-id="image"
        className="
        sui-order-1
        sm:sui-order-2
        sm:sui-basis-[24%]
        md:sui-basis-[16%]
        xl:sui-basis-[19%]
        2xl:sui-basis-[17%]
        sui-aspect-[2/1]
        sm:sui-aspect-[4/3]"
      >
        <img
          data-contentful-field-id="altText"
          className="
          sui-object-cover
          sui-w-full
          sui-h-full"
          src={imageUrl}
          alt={altText}
          width="100%"
          height="100%"
        />
      </div>
      {/* Content Container */}
      <div
        data-testid="medio-composable-banner-content-container"
        className={classNames(
          'sui-flex',
          'sui-items-center',
          'sm:sui-flex-nowrap',
          'sui-p-4',
          'sui-order-2',
          'sm:sui-order-1',
          'sm:sui-basis-[76%]',
          'md:sui-basis-[84%]',
          'xl:sui-basis-[81%]',
          '2xl:sui-basis-[83%]',
          'sui-paper-outlined',
          {
            'sui-flex-nowrap': !cta && smallMediaUrl,
            'sui-flex-wrap': cta,
          }
        )}
      >
        {/* Title and Subtitle Container */}
        <div
          data-testid="medio-composable-banner-title-and-subtitle-container"
          className={classNames('sui-w-full', 'sm:sui-w-auto', 'sm:sui-order-2', 'sm:sui-mb-0', {
            'sui-order-1': cta,
            'sui-mb-4': cta,
            'sui-order-2': !cta && smallMediaUrl,
          })}
        >
          <Typography
            variant="h3"
            height="tight"
            lineClamp={{
              default: '2',
              sm: '2',
              md: '1',
              lg: '1',
              xl: '1',
              '2xl': '1',
            }}
            data-contentful-field-id="title"
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              underline="none"
              variant="body-base"
              height="tight"
              lineClamp={{
                default: '2',
                sm: '3',
                md: '2',
                lg: '2',
                xl: '2',
                '2xl': '1',
              }}
              data-contentful-field-id="subtitle"
            >
              {subtitle}
            </Typography>
          )}
        </div>
        {/* CTA Button */}
        {cta && destinationUrl && (
          <div
            style={{ minWidth: 'fit-content' }}
            className={classNames('sm:sui-order-3', 'sm:sui-ml-auto', 'sm:sui-pl-4', '[min-width: fit-content]', {
              'sui-order-2': cta,
            })}
          >
            <Button
              href={destinationUrl}
              target={getTarget(destinationUrl)}
              variant="text"
              aria-label={`Navigate to ${title}`}
              data-contentful-field-id="cta"
            >
              {cta}
            </Button>
          </div>
        )}
        {/* Small Media Image */}
        {smallMediaUrl && (
          <div
            data-contentful-field-id="smallMedia"
            className={classNames(
              'sui-min-w-12',
              'sui-min-h-12',
              'sm:sui-mx-0',
              'sm:sui-mr-4',
              'sm:sui-order-1',
              'sui-flex',
              'sui-items-center',
              {
                'sui-order-1': !cta && smallMediaUrl,
                'sui-mr-4': !cta && smallMediaUrl,
                'sui-ml-auto': cta,
                'sui-order-3': cta,
              }
            )}
          >
            <img
              src={smallMediaUrl}
              alt={altTextSmallMedia}
              width="48px"
              height="48px"
              data-contentful-field-id="altTextSmallMedia"
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      data-testid="medio-composable-banner"
      data-contentful-entry-id={componentId}
    >
      {!cta && destinationUrl ? (
        <Link
          underline="none"
          href={destinationUrl}
          target={getTarget(destinationUrl)}
          data-contentful-field-id="destinationUrl"
        >
          {mainContent}
        </Link>
      ) : (
        mainContent
      )}

      {/* Legal Text */}
      {legalText && (
        <div className="sui-pt-2">
          {legalUrl ? (
            <Link href={legalUrl} target="_blank" variant="body-xs" data-contentful-field-id="legalCopy">
              {legalText}
            </Link>
          ) : (
            <Typography variant="body-xs" data-contentful-field-id="legalCopy">{legalText}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

MedioComposableBannerRenderer.propTypes = {
  data: shapeType({}),
  componentClass: string,
  componentId: string
};

MedioComposableBannerRenderer.defaultProps = {
  data: {},
  componentId: '',
  componentClass: 'MedioComposableBanner'
};

export { MedioComposableBannerRenderer };
